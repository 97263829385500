<template>
    <div class="grid">
        <div class="col-12">
            <div class="card">
                relation {{perf}}   
                <DataTable :value="relations" class="p-datatable-sm" responsiveLayout="scroll" showGridlines stripedRows
                :lazy="true" :paginator="true" :rows="10" ref="dt" dataKey="id"
                :totalRecords="totalRecords" :loading="loading" @page="onPage($event)" @sort="onSort($event)"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[10,25,50,75,100]"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                selectionMode="single"
                v-model:filters="filters" @filter="onFilter($event)" filterDisplay="row">
                    <template #header>
                        <div style="text-align:left">
                            <MultiSelect :modelValue="selectedColumns" :options="columns" optionLabel="header" @update:modelValue="onToggle"
                                placeholder="Select Columns" style="width: 20em"/>
                        </div>
                    </template>
                    <Column field="id" header="No" :sortable="true"></Column>
                    <Column field="rdate" header="日付" filterField="rdate" filterMatchMode="contains" ref="rdate" :sortable="true" :style="{'min-width': '10rem'}">
                        <template #filter="{filterModel,filterCallback}">
                            <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="p-column-filter" placeholder="日付" />
                        </template>
                    </Column>                    
                    <Column field="rtime" header="時刻" :sortable="true"></Column>
                    <Column field="rtype" header="rtype" filterField="rtype" filterMatchMode="contains" ref="rtype" :sortable="true" :style="{'min-width': '10rem'}">
                        <template #filter="{filterModel,filterCallback}">
                            <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="p-column-filter" placeholder="コード" />
                        </template>
                    </Column>
                    <Column field="rtypename" header="rtypename" filterField="rtypename" filterMatchMode="contains" ref="rtypename" :sortable="true" :style="{'min-width': '10rem'}">
                        <template #filter="{filterModel,filterCallback}">
                            <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="p-column-filter" placeholder="名称" />
                        </template>
                    </Column>
                    <Column field="rcd" header="rcd" filterField="rcd" filterMatchMode="contains" ref="rcd" :sortable="true" :style="{'min-width': '10rem'}">
                        <template #filter="{filterModel,filterCallback}">
                            <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="p-column-filter" placeholder="コード" />
                        </template>
                    </Column>    
                    <Column field="rname" header="rname" filterField="rname" filterMatchMode="contains" ref="rname" :sortable="true" :style="{'min-width': '10rem'}">
                        <template #filter="{filterModel,filterCallback}">
                            <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="p-column-filter" placeholder="名称" />
                        </template>
                    </Column>  
                    <Column field="ridx" header="ridx" :sortable="true"></Column>                              
                    <Column field="meigara_count" header="meigara_count" :sortable="true"></Column>
                    <Column field="1d_mean" header="1d_mean" :sortable="true">
                      <template #body="slotProps">
                        <div :class="priceClass(slotProps.data)">
                          {{slotProps.data["1d_mean"]}}
                        </div>
                      </template>
                    </Column>
                    <Column field="1d_std" header="1d_std" :sortable="true"></Column>                    
                    <Column v-for="(col, index) of selectedColumns" :field="col.field" :header="col.header" :key="col.field + '_' + index" filterField="meigara_cd_list" filterMatchMode="contains" ref="meigara_cd_list" :style="{'min-width': '10rem'}">
                        <template #filter="{filterModel,filterCallback}">
                            <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="p-column-filter" placeholder="コード" />
                        </template>                    
                    </Column>
                </DataTable>
            </div>
        </div>
    </div>
</template>

<script>
import RelationService from '@/service/RelationService';

export default {
    data() {
        return {
            loading: false,
            totalRecords: 0,
            relations: null,
            lazyParams: {},
            filters1: {},
            filters: {
                'rdate': {value: '', matchMode: 'contains'},
                'rtype': {value: '', matchMode: 'contains'},
                'rtypename': {value: '', matchMode: 'contains'},
                'rcd': {value: '', matchMode: 'contains'},
                'rname': {value: '', matchMode: 'contains'},
                'meigara_cd_list': {value: '', matchMode: 'contains'},
            },
            perf: null,
            selectedColumns: null,
            columns: null,
        }
    },
    relationService: null,
    created() {
        this.relationService = new RelationService();

        this.columns = [
            {field: 'meigara_cd_list', header: 'meigara_cd_list'},
            //{field: 'meigara_name_list', header: 'meigara_name_list'},
        ];
        this.selectedColumns = this.columns;        
    },
    mounted() {
        this.loading = true;

        this.lazyParams = {
            first: 0,
            rows: this.$refs.dt.rows,
            sortField: "id",
            sortOrder: "-1",
            filters: this.filters
        };

        this.loadLazyData();
    },
    methods: {
        loadLazyData() {
            this.loading = true;

            setTimeout(() => {
                this.relationService.getAll(
                    {dt_params: JSON.stringify( this.lazyParams )})
                        .then(data => {
                            this.relations = data.payload.data;
                            this.totalRecords = data.payload.total;
                            this.loading = false;
                            this.perf = data.perf;
                    }
                );
            }, Math.random() * 1000 + 250);
        },
        onPage(event) {
            this.lazyParams = event;
            this.loadLazyData();
        },
        onSort(event) {
            this.lazyParams = event;
            this.loadLazyData();
        },
        onFilter() {
            this.lazyParams.filters = this.filters;
            this.loadLazyData();
        },
        priceClass(data) {
          return [
            {
              'positive': data["1d_mean"] > 0,
              'negative': data["1d_mean"] < 0,
              'neutral': data["1d_mean"] == 0,
            }
          ];
        },
        onToggle(value) {
            this.selectedColumns = this.columns.filter(col => value.includes(col));
        }        
    }
}
</script>

<style scoped lang="scss">
@import '../assets/prod/styles/prices.scss';
</style>